import styled from 'styled-components';

import {color, font} from '../../../styles/variables';

export const StyledStep = styled.span`
    display: block;
    text-align: center;
    font-family: ${font.type.serif};
    font-size: ${font.size.xlarge};
    font-weight: ${font.weight.semibold};
    color: ${color.petrol};
`;
