import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';

import Image from '../../../components/Image';
import {
    ContentWrapper,
    ImageWrapper,
    MetaWrapper,
    Points,
    Duration,
    Type,
    LinkWrapper,
    NoLinkWrapper,
} from './styles';
import Headline from '../../../components/Headline';
import Icon from '../../../components/Icon';
import Button from '../../../components/Button';
import {makeUrl} from '../../../../model/backend-api';
import {selectHasUserReachedPointLimit} from '../../../../model/profile';
import {open as openPointLimitLightbox} from '../../../../model/point-limit-lightbox';

export const Teaser = ({
    title,
    type,
    image,
    points,
    duration,
    completed,
    route,
    isReleased,
    hasUserReachedPointLimit,
    onClickIfUserHasReachedPointLimit,
}) => {
    const teaserContent = (
        <>
            <ImageWrapper>
                <Image src={makeUrl(image)} alt={title} />
                <Type>{type}</Type>
                <Button
                    label={
                        typeof window === 'undefined' || isReleased
                            ? 'Training starten'
                            : 'Coming soon'
                    }
                    maxContent
                    ghost={isReleased}
                    icon={isReleased ? 'arrowForward' : null}
                    iconRight={isReleased}
                />
            </ImageWrapper>
            <ContentWrapper>
                <Headline type="h6" align="left" fontWeight="bold">
                    {title}
                </Headline>
                <MetaWrapper>
                    <Points>
                        <Icon name="star" size="1.2rem" />
                        {points}
                    </Points>
                    <Duration>
                        {duration}
                        {completed && <Icon name="checkmark" size="1.2rem" />}
                    </Duration>
                </MetaWrapper>
            </ContentWrapper>
        </>
    );
    return typeof window === 'undefined' || isReleased ? (
        <LinkWrapper
            className="ap-teaser"
            href={route}
            onClick={
                hasUserReachedPointLimit
                    ? (event) => {
                          event.preventDefault();
                          onClickIfUserHasReachedPointLimit();
                      }
                    : null
            }>
            {teaserContent}
        </LinkWrapper>
    ) : (
        <NoLinkWrapper className="ap-teaser">{teaserContent}</NoLinkWrapper>
    );
};

Teaser.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
    image: PropTypes.string,
    points: PropTypes.number,
    duration: PropTypes.string,
    completed: PropTypes.bool,
    route: PropTypes.string,
    isReleased: PropTypes.bool,
    hasUserReachedPointLimit: PropTypes.bool,
    onClickIfUserHasReachedPointLimit: PropTypes.func,
};

Teaser.defaultProps = {
    isReleased: true,
    hasUserReachedPointLimit: false,
};

export default function ConnectedTeaser(props) {
    const dispatch = useDispatch();
    // NOTE: Default to unconnected component inside storybook environment
    if (process.env.STORYBOOK_ENV === 'true') {
        return <Teaser {...props} />;
    }
    // eslint-disable-next-line
    const hasUserReachedPointLimit = useSelector(
        selectHasUserReachedPointLimit
    );
    return (
        <Teaser
            {...props}
            hasUserReachedPointLimit={hasUserReachedPointLimit}
            onClickIfUserHasReachedPointLimit={() =>
                dispatch(openPointLimitLightbox())
            }
        />
    );
}

ConnectedTeaser.propTypes = Teaser.propTypes;
