import React, {useContext} from 'react';
import PropTypes from 'prop-types';
import {useSelector} from 'react-redux';

import PreferredCategoriesForm from '../PreferredCategoriesForm';
import UserTypeForm from '../UserTypeForm';
import RegisterForm from '../../registration/RegisterForm';
import LocationContext from '../../../utils/LocationContext';
import {matchLocation} from '../../../../utils/route-utils';
import {routes} from '../../../../model/navigation/routes';
import Step from '../../../components/Step';

import {
    selectTempData,
    selectUserCategories,
    selectUserType,
} from '../../../../model/profile';

export const MultiStepPersonalization = ({steps, step}) => {
    return (
        <>
            <Step>{`${step}/${steps}`}</Step>
            {step === 1 && <PreferredCategoriesForm isStandalone={false} />}
            {step === 2 && <UserTypeForm isStandalone={false} />}
            {step === 3 && <RegisterForm theme="blue" />}
        </>
    );
};

MultiStepPersonalization.propTypes = {
    steps: PropTypes.number.isRequired,
    step: PropTypes.number.isRequired,
};

const ConnectedMultiStepPersonalization = (props) => {
    const {
        categories: tempUserCategories,
        userType: tempUserType,
    } = useSelector(selectTempData);
    const userCategories = useSelector(selectUserCategories);
    const userType = useSelector(selectUserType);
    const areUserCategoriesSet =
        (tempUserCategories && tempUserCategories.length > 0) ||
        userCategories.length > 0;
    const isUserTypeSet = tempUserType != null || userType !== '';
    const location = useContext(LocationContext);
    const steps = matchLocation(routes.start, location) ? 3 : 2; // third step only on start page
    const step = areUserCategoriesSet ? (isUserTypeSet ? 3 : 2) : 1;
    return <MultiStepPersonalization {...props} steps={steps} step={step} />;
};

export default ConnectedMultiStepPersonalization;
