import React from 'react';
import PropTypes from 'prop-types';

import {StyledStep} from './styles';

const Step = ({children}) => <StyledStep>{children}</StyledStep>;

Step.propTypes = {
    children: PropTypes.string,
};

export default Step;
