import styled, {css} from 'styled-components';

import {color, shadow} from '../../../../styles/variables';
import Link from '../../../components/Link';

const wrapperStyles = css`
    display: flex;
    flex-direction: column;
    height: 100%;
    cursor: pointer;
    transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
    background-color: ${color.white};
    color: ${color.black};

    * {
        max-width: 100%;
    }

    .ap-text {
        background-image: none;
    }

    &:focus,
    &:hover {
        box-shadow: ${shadow.blackLight};
        transform: translate(0, -2px) scale(1.1);
    }
`;

export const ImageWrapper = styled.div`
    position: relative;

    img {
        display: block;
        width: 100%;
        height: auto;
    }

    &:after {
        z-index: 0;
        content: '';
        background-color: ${color.red};
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    .ap-button {
        z-index: 1;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    .ap-teaser:hover &:after {
        opacity: 0.8;
    }

    .ap-teaser:hover & .ap-button {
        opacity: 0.8;
    }
`;

export const LinkWrapper = styled(Link)`
    ${wrapperStyles};
`;

export const NoLinkWrapper = styled.div`
    ${wrapperStyles};
    transition: none;

    &:focus,
    &:hover {
        transform: none;
        box-shadow: none;
        cursor: default;

        &.ap-teaser .ap-button {
            opacity: 1;
        }
    }

    ${ImageWrapper} {
        opacity: 0.4;

        &:after {
            background: none;
        }

        .ap-button {
            z-index: 1;
            opacity: 1;
            transition: none;

            &:focus,
            &:hover {
                cursor: default;
                background-color: ${color.red};
                box-shadow: none;
            }
        }
    }
`;

export const ContentWrapper = styled.div`
    padding: 1rem;
`;

export const MetaWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    svg {
        fill: ${color.petrol};
    }
`;

export const Points = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.9em;

    .ap-icon {
        margin-right: 0.5em;
    }
`;

export const Duration = styled.div`
    display: flex;
    align-items: center;
    font-size: 0.9em;

    .ap-icon {
        margin-left: 0.5em;
    }
`;

export const Type = styled.span`
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.4rem 0.5rem;
    transform: translate(0, 50%);
    background-color: ${color.snow};
    color: ${color.grey};
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 0.8rem;
    z-index: 10;
`;
